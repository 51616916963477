








































































import { GET_PAST_DELIVERIES } from '@/graphql/queries';
import { IPaginatedDelivery } from '@/interfaces/IDelivery.interface';
import { IPageInfo } from '@/interfaces/IPageInfo.interface';
import { DeliveriesMixin } from '@/mixins';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { format } from 'date-fns';

@Component
export default class PastDeliveriesView extends Mixins(DeliveriesMixin) {
  private search: string = '';
  private loading: boolean = false;
  private searching: boolean = false;
  private sortBy: Array<string> = [];
  private sortDir: boolean = false;

  private page: number = 1;
  private itemsPerPage: number = 15;
  private totalItems: number = 0;
  private pageInfo: IPageInfo;

  private deliveries: IPaginatedDelivery[] = [];

  private headers: DataTableHeader[] = [
    {
      text: '#',
      value: 'id',
    },
    {
      text: 'Customer',
      value: 'customer.firstname',
    },
    {
      text: 'Driver',
      value: 'driver.firstname',
    },
    {
      text: 'Dispatcher',
      value: 'createdBy.firstname',
    },
    {
      text: 'Drop-off',
      value: 'customer.address',
    },
    {
      text: 'Date Delivered',
      value: 'deliveredAt',
    },
    {
      text: 'Time',
      value: 'time',
      sortable: false,
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      width: '50px',
    },
  ];
  // LifeCycle
  created() {
    this.getPastDeliveries();
  }

  formatDate(date: string) {
    return format(new Date(date), 'MMM do yyyy');
  }

  @Watch('page')
  changePage(newValue, oldValue) {
    this.getPastDeliveries(newValue - oldValue);
  }
  @Watch('search')
  searchUsers() {
    if (this.search.length > 3 || this.search.length === 0) {
      this.getPastDeliveries();
    }
  }

  async onReopenDelivery(id: number) {
    try {
      await this.reopenDelivery(id);
      this.getPastDeliveries();
      this.$notify({
        group: 'main',
        type: 'success',
        title: 'Reopened',
        text: 'Delivery Reopened',
      });
    } catch (e) {
      this.$notify({
        group: 'main',
        type: 'error',
        title: 'Error',
        text: 'Unable to reopen Delivery',
      });
    }
  }

  @Watch('sortBy')
  @Watch('sortDir')
  async getPastDeliveries(
    direction = 0,
    fetchPolicy: 'cache-first' | 'network-only' = 'network-only',
  ) {
    const variables: {
      first?: number;
      last?: number;
      after?: string;
      before?: string;
      sortBy?: string;
      sortDir?: string;
      search: string;
    } = {
      search: `%${this.search}%`,
      sortBy: 'id',
      sortDir: 'ASC',
    };

    if (this.sortBy[0]) {
      variables.sortBy = this.sortBy[0];
    }

    if (this.sortDir[0]) {
      variables.sortDir = this.sortDir[0] ? 'DESC' : 'ASC';
    }

    if (direction !== -1) {
      variables.first = this.itemsPerPage;
      variables.after = direction === 1 ? this.pageInfo?.endCursor : '';
    } else {
      // Load Previous Page
      if (this.pageInfo.hasPreviousPage) {
        variables.last = this.itemsPerPage;
        variables.before = this.pageInfo.startCursor;
      } else {
        variables.first = this.itemsPerPage;
      }
    }

    const { data } = await this.$apollo.query({
      query: GET_PAST_DELIVERIES,
      variables,
      fetchPolicy: fetchPolicy,
    });

    this.deliveries = data.deliveries.edges;
    this.pageInfo = data.deliveries.pageInfo;
    this.totalItems = data.deliveries.totalCount;
  }
  customFilter(value: any, queryText, item) {
    const simplifiedValue = `${item.node.id}
    ${item.node.customer.firstname} ${item.node.customer.lastname}
    ${item.node.driver.firstname} ${item.node.driver.lastname}
    ${item.node.createdBy.firstname} ${item.node.createdBy.lastname}
    ${item.node.customer.address} ${item.node.customer.city} ${item.node.customer.province} ${
      item.node.customer.postal
    }
    ${this.formatDate(item.node.deliveredAt)}`;

    const searchText = queryText.toLowerCase();

    return simplifiedValue.toLowerCase().indexOf(searchText) > -1;
  }
}
